<template>
  <section
    v-observe-visibility="visibilityChanged"
    class="jobSection font-Pretendard"
    :style="[ $screen.breakpoint !== 'mobile' ? { backgroundImage: 'url(' + sectionBg + ')' } : { backgroundImage: 'url(' + sectionMobileBg + ')' } ]"
    data-aos="fade-up"
    data-aos-duration="900"
  >
    <div
      class="flex flex-col items-center text-white "
    >
      <p
        class="
          pc:max-w-[620px] text-center
          pc:text-[44px] pc:leading-[58px] pc:tracking-[-0.4px] pc:mx-0
          tablet:text-[36px] tablet:leading-[46px] tablet:tracking-[-0.3px] tablet:mx-0
          mobile:text-[30px] mobile:leading-[38px] mobile:tracking-[-0.3px] mobile:mx-[64px]
          font-bold
        "
        data-aos="fade-up"
        data-aos-duration="900"
        :style="'word-wrap: break-word; word-break: keep-all;'"
      >
        더 <span class="text-orange-500">즐거운 세상</span>
        <br v-if="$screen.breakpoint !== 'mobile'">
        함께 
        <br v-if="$screen.breakpoint === 'mobile'">만들어가실래요?
      </p>
      <p
        class="
          pc:max-w-[500px] tablet:max-w-[450px] mobile:max-w-[400px]
          pc:text-[20px] pc:leading-[1.7] pc:tracking-[-0.2px] pc:mt-[10px]
          tablet:text-[18px] tablet:leading-[30px] tablet:tracking-[-0.1px] tablet:mt-3 tablet:mx-0
          mobile:text-[16px] mobile:leading-[27px] mobile:tracking-[-0.1px] mobile:mt-2 mobile:mx-6
          text-center 
        "
        :style="'word-wrap: break-word; word-break: keep-all;'"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        게임으로 세상을 더 즐겁게 만드는 일, 
        <br v-if="$screen.breakpoint === 'mobile'">
        여러분과 함께 이뤄내고
        <br v-if="$screen.breakpoint !== 'mobile'">
        싶어요. 
        <br v-if="$screen.breakpoint === 'mobile'">
        쿡앱스의 비전에 가슴이 떨린다면, 
        <br v-if="$screen.breakpoint === 'mobile'">
        지금 바로 합류하세요!
      </p>
    </div>
    <div class="flex justify-center">
      <CustomButton
        data-aos="fade-up"
        data-aos-duration="900"
        class=" pc:mt-9 tablet:mt-6 mobile:mt-6 font-Pretendard"
        text="채용 중인 포지션 보기"
        :size="$screen.breakpoint === 'pc' ? '2xl' : 'xl'"
        rounded
        color="secondary"
        @click.native="$router.push('/careers/list')"
      />
    </div>
  </section>
</template>

<script>
import CustomButton from '../../../components/Button/customButton.vue';
export default {
  name: 'Hire',
  components: { CustomButton },
  data() {
    return {
      sectionBg: require('@/assets/about/image-05.png'),
      sectionMobileBg: require('@/assets/about/image-05.png'),
      hiringIsVisible: false,
      offsetTop: 0,
      offsetHeight: 0,
      value: 0,
      fistTxtOn: false,
      secondTxtOn: false,
      thirdTxtOn: false,
    };
  },
  // created() {
  //   window.addEventListener('scroll', this.handleScroll);
  // },
  // destroyed() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.hiringIsVisible = isVisible;
      this.offsetHeight = entry.target.offsetHeight;
      this.value = this.offsetHeight / 4;
      this.offsetTop = entry.target.offsetTop + this.value;
    },
    handleScroll() {
      if (this.hiringIsVisible) {
        const scrollY = window.scrollY + window.innerHeight;
        if (scrollY > this.offsetTop && scrollY <= this.offsetTop + this.value * 2) {
          this.fistTxtOn = true;
          this.secondTxtOn = false;
          this.thirdTxtOn = false;
        }
        else if (scrollY > this.offsetTop + this.value * 2 && scrollY <= this.offsetTop + this.value * 3) {
          this.secondTxtOn = true;
          this.fistTxtOn = false;
          this.thirdTxtOn = false;
        }
        else if (scrollY > this.offsetTop + this.value * 3) {
          this.thirdTxtOn = true;
          this.fistTxtOn = false;
          this.secondTxtOn = false;
        }
        else {
          this.fistTxtOn = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.jobSection{
  position: relative;
  padding: rem(160) 1rem rem(160);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0; left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%; height: 100%;
  }
  & > div{
    position: relative;
    z-index: 1;
  }
  .customBtn span{
    font-size: 20px;
    letter-spacing: -0.2px;
  }
}
.jobSection__title{
  max-width: 890px;
  margin: 0 auto;
  font-size: 42px;
  line-height: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.2px;
  color: #FFF;
  word-break: keep-all;
  :is(.tablet) &{
    max-width: 560px;
    word-break: keep-all;
    font-size: 28px;
    line-height: 1.57;
    letter-spacing: -0.3px;
  }
  :is(.mobile) &{
    max-width: 340px;
    word-break: keep-all;
    font-size: 22px;
    line-height: 1.55;
    letter-spacing: -0.1px;
  }
}
.jobSection__title span{
  transition: color 0.6s;
}
.jobSection__title span.on{
  color: $primaryColor;
}
.sub__text{
  word-break: keep-all;
}
:is( .mobile, .tablet ) .jobSection{
  .sub__text{
    word-break: keep-all;
    font-size: 1rem;
    line-height: 2rem;
  }
}
:is( .mobile ) {
  .jobSection{
    background-size: auto 100%;
    // padding: 95px 0;
    padding: 64px 0;
  }
  .extraLarge__text span{
    padding-right: 0.8rem;
  }
  .customBtn span{
    font-size: 1rem;
    letter-spacing: -0.1px;
  }
}
:is( .tablet ) {
  .jobSection{
    // padding: 93px 0;
    padding: 56px 0;
  }
}
</style>
