<template>
  <div class="fontFamilyNoto">
    <!-- <PageTopBanner :data="aboutTopBanner" /> -->

    <!-- Goal -->
    <Goal />

    <div
      v-if="$screen.breakpoint == 'pc'"
      style="height: 1px; background-color: #e1e1e1;"
    />

    <!-- Challenge new -->
    <ChallengeNew class="challengeNew" />

    <!-- History -->
    <History />
    <!-- History -->
    <News />

    <!-- Opportunity -->
    <!-- <Opportunity style="background-color: #F5F5F5;" /> -->

    <!-- Fun -->
    <!-- <Fun /> -->

    <!-- WorkTogether -->
    <!-- <WorkTogether style="overflow-x: hidden; background-color: #F5F5F5;" /> -->

    <!-- JobSection -->
    <JobSection />

    <!-- Video Modal -->
    <VideoModal v-if="$store.state.app.showYoutube" />
  </div>
</template>

<script>
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
import Goal from './components/goal.vue';
// import Opportunity from './components/opportunity.vue';
import History from './components/history.vue';
import ChallengeNew from './components/challenge_new.vue';
// import WorkTogether from './components/workTogether.vue';
import JobSection from './components/jobSection.vue';
// import Fun from './components/funSection.vue';
import VideoModal from '@/layout/components/VideoModal.vue';
import News from './components/news.vue';

export default {
  components: {
    // PageTopBanner,
    Goal,
    History,
    // Opportunity,
    ChallengeNew,
    // WorkTogether,
    // Fun,
    JobSection,
    VideoModal,
    News
  },
  data() {
    return {
      aboutTopBanner: {
        bg: require('@/assets/about/aboutPage__bannerBg.png'),
        title: 'About us',
        subtitle: '쿡앱스의 <b>미션과 가치, 목표</b> 를 소개합니다!.',
      },
    };
  },
  created(){
    window.fbq('track','cookapps_about_view');
  }
};
</script>