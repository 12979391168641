<template>
  <div>
    <div class="section__common__container goal">
      <div class="text-center goal__desc">
        <div
          class="font-bold text-orange-500
                pc:text-2xl pc:leading-[1.27] pc:mb-2.5
                tablet:text-lg tablet:leading-[1.33] tablet:mb-2
                mobile:text-base mobile:leading-[1.25] mobile:mb-2"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          OUR VISION
        </div>
        <div
          class="font-bold
                pc:mb-4 pc:text-5xl pc:leading-[1.27] pc:text-gray-700
                tablet:mb-3 tablet:text-[36px] tablet:leading-[1.44] tablet: tracking-[-0.3px] tablet:text-gray-800
                mobile:mb-2.5 mobile:text-3xl mobile:leading-[1.38] mobile:text-gray-800"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          게임으로 세상을<br>
          더 즐겁게 만듭니다
        </div>
        <div
          class="inline-block
                pc:text-xl pc:leading-[1.8] pc:text-gray-600 pc:mb-14
                tablet:text-lg tablet:leading-[1.83] tablet:text-gray-700 tablet:mb-12
                mobile:text-base mobile:leading-[1.69] mobile:text-gray-700 mobile:mb-12"
          style="word-break: keep-all;"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          매일 아침 서울의 출근길에서, 
          친구를 기다리는 LA의 카페에서,
          <br v-if="$screen.breakpoint !== 'mobile'"> 
          잠들기 전 도쿄의 침대맡에서 
          우리는 지금 이 순간에도 전 세계
          <br v-if="$screen.breakpoint !== 'mobile'">
          유저와 함께하고 있습니다. 
          ‘쿡앱스 덕분에 즐겁다’라는 말이
          <br v-if="$screen.breakpoint !== 'mobile'">
          당연해질 때까지, 
          우리는 멈추지 않습니다. 
        </div>
      </div>
      <div
        class="relative goal__video"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-offset="80"
        @click="showYoutube()"
      >
        <img
          class="goal__thumbnail"
          :src="require('@/assets/about/video.png')"
          alt="쿡앱스 소개영상 썸네일 이미지"
        >
        <button class="absolute goal__playBtn">
          <i class="xi-play xi-x" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: {
        // videoId: 'ees_Ik4UfLs'
        videoId: 'wG-vsPUSOkY'
      },
    };
  },
  methods: {
    showYoutube() {
      document.documentElement.style.overflowY = 'hidden';
      this.$store.dispatch('app/setShowYoutube', true);
      this.$store.dispatch('app/setCurrentVideo', this.video);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/about.scss";
.goal{
  padding: 72px 0 140px;
  &__video{
    width: 845px;
    height: 475px;
    // width: 893px;
    // height: 502px;
    margin: 0 auto;
    border-radius: 32px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    &:hover{
      cursor: pointer;
    }
    &:hover .goal__playBtn{
      background-color: rgba($color: $orange-500, $alpha: 1);
    }
  }
  &__playBtn{
    transition: background-color .4s;
    width: 120px; height: 120px;
    background-color: rgba($color: $orange-500, $alpha: .5);
    top: calc( 50% - 60px );
    left: calc( 50% - 60px );
    border-radius: 100%;
    overflow: hidden;
    .xi-play{
      color: #FFF;
      font-size: 96px;
      position: relative;
      left: 6px;
    }
  }
  &__desc .aboutPage__desc{
    word-break: keep-all;
    max-width: 724px;
  }
}
:is(.mobile, .tablet){
  .goal{
    width: calc( 100% - 2rem );
    margin: 0 auto;
    padding: 40px 0 88px;
    &__video{
      width: 100%;
      height: 0;
      padding-bottom: 56%;
      border-radius: 1rem;
    }
    &__thumbnail{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
    }
    &__playBtn{
      width: 64px; height: 64px;
      top: calc( 50% - 32px );
      left: calc( 50% - 32px );
      .xi-play{
        color: #FFF;
        font-size: 38px;
        position: relative;
        left: 3px;
        top: 1px;
      }
    }
    &__desc .aboutPage__desc{
      word-break: keep-all;
      max-width: 540px;
    }
  }
}
:is(.tablet){
  .goal{
    max-width: 720px;
    padding: 48px 0 148px !important;
  }
}
</style>