<template>
  <div>
    <div
      v-if="$screen.breakpoint === 'pc'"
      ref="wrapper"
      class="relative"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        ref="imgWrapper"
        class="sticky top-0 challenge__img--wrapper"
        style="display: inline-block;"
      >
        <div
          v-for="image in challengeImg"
          :id="`challengeImg__${image.idx+1}`"
          :key="`challengeImg__${image.idx+1}`"
          class="challenge__img"
        >
          <img
            :src="image.img"
            alt=""
          >
        </div>
      </div>
      <div
        ref="descWrapper"
        class="challenge__desc--wrapper"
      >
        <div
          v-for="desc in challengeDesc"
          :id="`challengeDesc__${desc.idx+1}`"
          :key="`challengeDesc__${desc.idx+1}`"
          class="challenge__desc"
        >
          <div>
            <div
              v-if="desc.smallTitle.length>0"
              class="text-2xl font-bold leading-1.27 text-orange-500 mb-3"
            >
              {{ desc.smallTitle }}
            </div>
            <!-- eslint-disable -->
            <div
              v-if="desc.title.length>0"
              class="text-5xl font-bold leading-[1.27] text-gray-700 mb-3"
              v-html="desc.title"
            />
            <div
              class="text-2xl text-gray-700 w-[513px]"
              style="word-break: keep-all;"
            >
              {{ desc.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$screen.breakpoint !== 'pc'"
      ref="wrapper"
      class="relative"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        ref="imgWrapper"
        class="sticky top-0 challenge__img--wrapper"
        style="display: inline-block;"
      >
        <div
          v-for="image in challengeImg"
          :id="`challengeImg__${image.idx+1}`"
          :key="`challengeImg__${image.idx+1}`"
          class="challenge__img"
          
        >
          <img
            :src="image.img"
            alt=""
          >
        </div>
      </div>
      <div
        ref="descWrapper"
        class="challenge__desc--wrapper"
      >
        <div
          v-for="desc in challengeDesc"
          :id="`challengeDesc__${desc.idx+1}`"
          :key="`challengeDesc__${desc.idx+1}`"
          class="challenge__desc"
        >
          <div>
            <div
              v-if="desc.smallTitle.length>0"
              class="text-orange-500 font-bold mb-2
                    tablet:text-lg tablet:leading-[1.33] tablet:tracking-[-0.1px]
                    mobile:text-base mobile:leading-[1.25]"
            >
              {{ desc.smallTitle }}
            </div>
            <!-- eslint-disable -->
            <div
              v-if="desc.title.length>0"
              class="font-bold text-gray-800
                    tablet:mb-3 tablet:text-[36px] tablet:leading-[1.44] tablet:tracking-[-0.3px]
                    mobile:mb-2 mobile:text-3xl mobile:leading-[1.38]"
              v-html="desc.title"
            />
            <div
              class="text-gray-700 
                    tablet:text-xl tablet:leading-[1.8]
                    mobile:text-base"
              style="word-break: keep-all;"
            >
              {{ desc.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      challenge: [],
      challengeImg: [
        {
          idx: 0,
          img: require('@/assets/about/challenge_img1.png'),
        },
        // {
        //   idx: 1,
        //   img: require('@/assets/about/challenge_img2.png'),
        // },
        {
          idx: 2,
          img: require('@/assets/about/challenge_img5.png'),
        },
        // {
        //   idx: 3,
        //   img: require('@/assets/about/challenge_img4.png'),
        // },
      ],
      challengeDesc: [
        {
          idx: 0,
          smallTitle: 'Challenge 1.',
          title: '빠르게 변하는 시장에서<br/>더 작고 빠르게 시도합니다',
          desc: '유저의 니즈와 시장의 상황이 하루가 다르게 변하는 지금, 더 작고 빠르게 시도합니다. 처음부터 완벽하게 만들지 않습니다. 최소 기능만으로 게임의 핵심 재미를 검증하는 쿡앱스만의 제작 프로세스를 통해 게임을 완성합니다.',
        },

        {
          idx: 2,
          smallTitle: 'Challenge 2.',
          title: '오늘도 내일도 즐기고 싶은<br/>유저를 위한 게임을 제작합니다',
          desc: '우리가 정의하는 성공 게임은 유저가 자주 접속하고 싶은, 그래서 곁에 오래 두며 애정을 쏟는 게임입니다. 자꾸만 하고 싶은 게임을 만들기 위해 유저의 재미에 집중하며 유저가 원하는, 유저가 재밌는 게임을 세상에 선보입니다.',
        },
      ],
      imgWrapperVisible: false,
      offsetHeight: 0,
      offsetTop: 0,
      standard: 0,
      scrollFlag: true,
    };
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    if(this.$screen.breakpoint !== 'pc'){
      this.challengeImg.forEach((item, no) => {
        const tempObj = {
          idx: no,
          img: item.img,
          smallTitle: this.challengeDesc[no].smallTitle,
          title: this.challengeDesc[no].title,
          desc: this.challengeDesc[no].desc
        };
        this.challenge.push(tempObj);
      });
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      const scrollY = window.scrollY;
      if(this.$refs.imgWrapper.getBoundingClientRect().y === 56){
        const offset = this.$refs.wrapper.clientHeight / 5;
        if(this.scrollFlag) {
          this.standard = scrollY; 
          this.scrollFlag = false;
        }
        if(scrollY < this.standard+offset){
          document.getElementById('challengeImg__1').style.opacity = 1;
          document.getElementById('challengeImg__3').style.opacity = 0;
          document.getElementById('challengeDesc__1').style.opacity = 1;
          document.getElementById('challengeDesc__3').style.opacity = 0;
        }else if(scrollY >= this.standard+offset && scrollY < this.standard+offset*2){
          document.getElementById('challengeImg__1').style.opacity = 0;
          document.getElementById('challengeImg__3').style.opacity = 1;
          document.getElementById('challengeDesc__1').style.opacity = 0;
          document.getElementById('challengeDesc__3').style.opacity = 1;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/about.scss";
.challenge{
  &__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    height: calc( 400vh - (56px * 4) );
  }
  &__img--wrapper{
    position: -webkit-sticky;
    position: sticky;
    top: 56px;
    width: 50%;
    height: calc( 100vh - 56px );
  }
  &__img{
    transition: opacity .8s;
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    display: flex;
    width: 50vw;
    padding-right: 64px;
    align-items: center;
    justify-content: flex-end;
    &:not(:first-child){
      opacity: 0;
    }
    img{
      width: 578px;
      max-width: 80%;
    }
  }
  &__desc--wrapper{
    margin-top: -33%;
    text-align: left;
    width: 100%;
    height: auto;
    padding-left: 52.5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  &__desc{
    transition: opacity .2s;
    height: 80vh;
    display: inline-block;
    max-width: 534px;
    margin-bottom: 15%;
    &:last-child{
      height: 56vh;
    }
  }
}

:is(.tablet, .mobile){
  .challenge__img img{
    width: 100%;
    max-width: unset;
    height: calc( 50vh - 56px );
    object-fit: cover;
    position: relative;
    z-index: 2;
  }
  .challenge__img{
    padding: 0;
    display: block;
    width: 100%;
  }
  .challenge__img--wrapper{
    overflow: hidden;
    width: 100%;
    height: calc( 50vh - 56px );
    background: #FFF;
  }
  .challenge__desc--wrapper{
    margin-top: 7vh;
    padding-left: 0;
    text-align: center;
    position: relative;
    z-index: -1;
    padding: 0 28px;
    display: flex;
    flex-direction: column;
  }
  .challenge__desc{
    opacity: 1 !important;
    // margin-bottom: 15%;
  }
}

:is(.tablet){
    .challenge__desc {
      margin: 154px auto 0;
    }
  }
:is(.mobile){
  .challenge__desc {
    margin: 97px auto 0;
  }
}
</style>