<template>
  <div class="modal">
    <div class="modal__container">
      <button
        class="closeBtn"
        @click="closeModal"
      >
        <span class="xi-close" />
      </button>
      <div class="modal__video">
        <iframe
          width="100%"
          height="400"
          :src="`https://www.youtube.com/embed/${currentContent.videoId}`"
          allowfullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentContent: {},
    };
  },
  created() {
    this.currentContent = this.$store.state.app.currentVideo;
  },
  methods: {
    closeModal() {
      document.documentElement.style.overflowY = 'unset';
      this.$store.dispatch('app/setShowYoutube', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal{
    position: fixed;
    z-index: 9999;
    top: 0; left: 0;
    width: 100%; height: 100vh;
    background-color: rgba($color: #000000, $alpha: .5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    &__container{
        position: relative;
        width: 95vw;
        max-width: 920px;
    }
    .closeBtn{
        transition: color .4s;
        position: absolute;
        top: -54px;
        right: 0;
        font-size: 2.4rem;
        &:hover{
            color: $primaryColor;
        }
    }
}
:is(.mobile){
    .closeBtn{
        position: absolute;
        top: -38px;
        right: 0;
        font-size: 1.4rem;
    }
}
.modal__video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    background-color: #000;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}
</style>
