<template>
  <div 
    class="
      flex flex-col items-center
      pc:pb-[226px] 
      tablet:px-[32px] tablet:pb-[198px]
      mobile:px-[16px] mobile:pb-[134px]
    "
  >
    <div class="pc:min-w-[1064px] pc:max-w-[1064px] w-full">
      <div
        class="
          pc:mt-[114px] pc:pb-[24px] 
          tablet:mt-[56px] tablet:pb-[17px]
          mobile:mt-[48px] mobile:pb-[16px]
          border-b-[1px] border-black
        "
      >
        <p 
          class="
            pc:text-[44px] pc:tracking-[-0.4px] pc:leading-[56px] 
            tablet:text-[30px] tablet:tracking-[-0.3px] tablet:leading-[38px] 
            mobile:text-[26px] mobile:tracking-[-0.2px] mobile:leading-[32px] 
            text-gray-800
            font-bold
          "
        >
          언론 속의
          <span class="text-orange-500">
            쿡앱스
          </span>
        </p>
      </div>
      <div
        v-for="(n, i) in news"
        :key="i"
        class="
          pc:py-[32px]
          tablet:py-[32px]
          mobile:py-[20px]
          border-b-[1px] border-gray-200
        "
      >
        <div class="flex justify-between">
          <div class="flex flex-col">
            <a
              :href="n.link"
              target="_blank"
              class="
              pc:text-[22px] pc:tracking-[-0.2px] pc:leading-[28px] 
              tablet:text-[22x] tablet:tracking-[-0.2px] tablet:leading-[28px] 
              mobile:text-[18px] mobile:tracking-[-0.1px] mobile:leading-[26px]
              hover:text-orange-500
              text-gray-800
            "
            >
              {{ n.title }}
            </a>
            <div
              class="
              pc:text-[16px] pc:tracking-[-0.2px] pc:leading-[28px] pc:mt-[16px] 
              tablet:text-[16px] tablet:tracking-[-0.1px] tablet:leading-[20px] tablet:mt-[16px] 
              mobile:text-[14px] mobile:tracking-[-0.1px] mobile:leading-[18px] mobile:mt-[10px] 
              text-gray-500
            "
            >
              <div>
                <span
                  class="
                  pc:pr-[10px] pc:mr-[10px] pc:leading-[1.25] pc:mt-[16px] 
                  tablet:pr-[10px] tablet:mr-[10px] tablet:leading-[1.25] tablet:mt-[16px]
                  mobile:pr-[8px] mobile:mr-[8px] mobile:leading-[1.25] mobile:mt-[10px]
                  border-r-[1px] border-gray-300
                "
                >{{ n.date }}</span>
                <span>{{ n.press }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="$screen.breakpoint !== 'mobile'"
            class="flex items-center justify-center"
          >
            <i class="el-icon-arrow-right text-[24px] text-gray-300" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      news: [{
        title: '방치형 액션 RPG ‘광전사 키우기’, 네이버 웹툰 ‘투신전생기’와 콜라보!',
        date: '2023.07.27',
        press: '게임조선',
        link: 'https://www.gamechosun.co.kr/webzine/article/view.php?no=197535'
      },{
        title: '‘테일드 데몬 슬레이어: 라이즈’, 구글 인기 1위 달성!',
        date: '2023.07.04',
        press: '지디넷코리아',
        link: 'https://zdnet.co.kr/view/?no=20230704144237'
      },{
        title: '수집형 도트 액션 RPG ‘무명기사단’, 양대마켓 인기 1위 달성',
        date: '2023.06.09',
        press: '디스이즈게임',
        link: 'https://www.thisisgame.com/webzine/game/nboard/225/?n=171134'
      },{
        title: '쿡앱스 2022년 매출 830억…최대실적 갱신!',
        date: '2023.05.24',
        press: '이데일리',
        link: 'https://www.edaily.co.kr/news/read?newsId=01718726635612200&mediaCodeNo=257&OutLnkChk=Y'
      },{
        title: '‘광전사키우기’ 역주행!...앱스토어 인기 1위 기록!',
        date: '2023.04.04',
        press: '전자신문',
        link: 'https://www.etnews.com/20230404000114'
      },{
        title: '쿡앱스, ‘2022 대한민국 중소기업 규제혁신’ 장관상 수상',
        date: '2022.12.26',
        press: '데일리안',
        link: 'https://www.dailian.co.kr/news/view/1187075'
      },{
        title: '‘테일드 데몬 슬레이어’, 구글 선정 올해를 빛낸 캐주얼 게임 ‘우수상’',
        date: '2022.12.01',
        press: '게임조선',
        link: 'https://www.gamechosun.co.kr/webzine/article/view.php?no=191008'
      },{
        title: '업계 최초, 쿡앱스 연봉 테이블 공개…직군 무관 신입 5000만원부터',
        date: '2021.10.26',
        press: '게임포커스',
        link: 'http://gamefocus.co.kr/detail.php?number=122938'
      },
      // {
      //   title: '쿡앱스, 방치형 RPG ‘테일드 데몬 슬레이어’ 올해를 빛낸 캐주얼 게임 ‘우수상’ 최종 선정',
      //   date: '2022.12.01',
      //   press: '게임조선',
      //   link: 'https://www.gamechosun.co.kr/webzine/article/view.php?no=191008'
      // },{
      //   title: '쿡앱스, 방치형 액션 RPG ‘광전사 키우기’ 구글 인기 1위 달성',
      //   date: '2022.10.07',
      //   press: '인벤',
      //   link: 'https://www.inven.co.kr/webzine/news/?news=277269'
      // },{
      //   title: '쿡앱스 "올 상반기 실적, 지난해 연간 실적에 근접"',
      //   date: '2022.06.30',
      //   press: '글로벌이코노믹',
      //   link: 'https://news.g-enews.com/ko-kr/news/article/news_all/202206301612502900c5fa75ef86_1/article.html'
      // }, {
      //   title: '쿡앱스, 개발 스튜디오 젊게 개편...20대 강현빈·김병우 디렉터 선임',
      //   date: '2022.03.26',
      //   press: '지디넷코리아',
      //   link: 'https://zdnet.co.kr/view/?no=20220326101941'
      // },{
      //   title: '쿡앱스, 사내 게임잼 우승 작품 정식 라인업으로 편입',
      //   date: '2022.04.08',
      //   press: '지디넷코리아',
      //   link: 'https://zdnet.co.kr/view/?no=20220408110756'
      // }, {
      //   title: '‘테일드 데몬 슬레이어’ 구글 플레이 인기 순위 1위 달성',
      //   date: '2022.02.15',
      //   press: '게임조선',
      //   link: 'https://www.gamechosun.co.kr/webzine/article/view.php?no=182209'
      // },
      // {
      //   title: '쿡앱스 게임 타이틀 19년 이후 2년 만에 전 세계 누적 다운로드 2억 기록 달성',
      //   date: '2021.09.28',
      //   press: '게임조선',
      //   link: 'https://www.gamechosun.co.kr/webzine/article/view.php?no=178235'
      // }, {
      //   title: '쿡앱스, 2021년 영업익 전년比 30%↑…\'역대 최대\'',
      //   date: '2022.01.12',
      //   press: '글로벌이코노믹',
      //   link: 'https://news.g-enews.com/ko-kr/news/article/news_all/202201121607564828c5fa75ef86_1/article.html?md=20220112173529_U'
      // }, {
      //   title: '쿡앱스, 고용부 주관 ‘워라밸 실천 우수기업’ 선정…게임업계 \'유일\'',
      //   date: '2021.12.10',
      //   press: '데일리안',
      //   link: 'https://www.dailian.co.kr/news/view/1061763/?sc=Naver'
      // }, {
      //   title: '쿡앱스 방치형RPG \'오늘도 던전\' 5개월만에 순위 역주행. 100만 다운로드 돌파',
      //   date: '2021.11.11',
      //   press: '게임동아',
      //   link: 'https://game.donga.com/101229/'
      // }, {
      //   title: '테일드 데몬 슬레이어, 10월 말에 만나요',
      //   date: '2021.10.18',
      //   press: '내외경제TV',
      //   link: 'https://www.nbntv.co.kr/news/articleView.html?idxno=937145'
      // },
      ]
    };
  }
};
</script>
<style>
  
</style>